.ProjectSettings {
    $this: &;
    $_width: 60.143rem;

    position: fixed;
    z-index: 10;

    width: 100%;
    height: 100%;

    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;

    * + label {
        margin-left: 1rem;
    }

    &.active {
        opacity: 1;
        pointer-events: all;
        transition: opacity .3s;

        #{$this}-window {
            left: 0;
            transition: left .6s;
        }
    }

    &-gutter {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: rgba($color_bg-dark, .4);
    }

    &-window {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: -$_width;

        width: $_width;
        height: 100vh;

        background: $color_bg-light;
        border-radius: 0 $box_border-radius--medium $box_border-radius--medium 0;
        overflow: hidden;
        transition: left .6s;
    }

    &-header {
        padding: $dims_padding-large;

        color: $color_light-blue;
        font-size: $font_size-large;
        font-weight: 400;

        background: $color_lightest-blue;
    }

    &-sections {
        flex: 1 auto;
        overflow-y: auto;
        padding: 0 $dims_padding-large;
    }

    &-section {
        padding: $dims_padding-large 0;
        border-bottom: 1px solid $color_neutral;
    }

    &-entry {
        display: flex;
        padding: $dims_padding 0;

        & > label {
            flex: 0 0 12.857rem;
        }

        label {
            vertical-align: middle;
            text-align: right;
            margin: auto 0;
            margin-right: $dims_padding-large;

            font-size: $font_size-medium;
        }

        * + label {
            margin-left: 1rem;
        }

        &-col {
            display: flex;
        }

        select, input {
            width: 27.143rem;
            padding: $dims_padding;

            font-size: $font_size-regular;

            background: $color_bg-light;
            border: 1.5px solid $color_neutral;
            border-radius: $box_border-radius;

            &[type="number"] {
                width: 5rem;
            }
        }
    }

    &-cell {
        display: inline-block;
        top: -$font_size-xsmall;
        margin-top: 3px;

        &:nth-of-type(1) {
            margin-right: $dims_padding;

            .cell-value, select {
                width: 7.743rem;
            }
        }

        label {
            display: block;

            color: $color_neutral;
            font-size: $font_size-regular;
            text-align: left;
        }

        input {
            width: 4.286rem;
        }

        .cell-value {
            width: auto;
            min-width: 4.286rem;
        }
    }

    .cell-value {
        padding: $dims_padding $dims_padding;

        font-size: $font_size-regular;
        text-align: center;

        background: $color_lightest-blue;
        border-radius: $box_border-radius;
    }

    &-input {
        display: flex;
    }

    .labelled-input {
        margin: auto 0;
        padding: 0;
        border: 1px solid $color_neutral;
        border-radius: $box_border-radius;
        overflow: hidden;

        + .labelled-input {
            margin-left: 1rem;
        }

        input {
            width: 4rem;

            border: none;
            outline: none;
        }

        label, span {
            display: inline-block;
            top: -1px;

            padding: $dims_padding;
            margin: 0;
            font-size: $font_size-regular;

            color: $color_neutral;
            background: $color_bg-medium;
            text-transform: uppercase;
        }

        &.readonly {
            pointer-events: none;
            border-color: $color_bg-medium;

            label, span {
                background: $color_bg-light;
            }

            .cell-value {
                display: inline-block;
            }
        }
    }

    .project-type-entry {
        margin-bottom: $dims_padding;
    }

    &-footer {
        padding: $dims_padding;
        text-align: right;

        button:not(.delete-project) {
            font-weight: 500;
            font-size: $font_size-medium;

            border-radius: $box_border-radius--small;
            box-shadow: $box_shadow;

            + button {
                margin-left: $dims_padding;
            }
        }

        .delete-project {
            float: left;
        }
    }
}

.delete-project-confirmation {
    p {
        margin-top: 0;
        font-weight: 400;
    }
}

.CostMetricSettings {
    .cost {
        input, .cell-value {
            width: 8.926rem;
        }
    }

    .ProjectSettings-cell {
        margin-left: $dims_padding-large;
    }

    .ProjectSettings-input {
        flex: 1 0 auto;
    }

    .escalation input {
        width: 4rem;
    }

    .escalation-year {
        input {
            width: 4rem;
        }
    }

    .ProjectSettings-entry-col {
        flex: 1 0 auto;

        label {
            flex: 1 0 auto;
        }
    }
}

.SpaceTypeMetricSettings {
    select {
        width: 14.286rem !important;
    }

    .labelled-input input {
        width: 5.714rem;
    }

    .GSF {
        margin-left: $dims_padding;

        .cell-value {
            width: auto;
        }
    }
}
