.WelcomeModal {

    .ReactModal {
        &__window {
            text-align: center;
        }

        &__content {
            padding: $dims_padding-large * 5;
        }
    }

    h1 {
        margin-top: 0;

        font-size: $font_size-xlarge;
        font-weight: 500;
        line-height: 1.5em;
    }

    img {
        margin-top: $dims_padding-large * 2;
    }

    p {
        width: 90%;
        max-width: 42.857rem;
        margin: $dims_padding-large * 3 auto 0;

        font-size: $font_size-medium;
        line-height: 1.5em;
        text-align: left;
    }

    button.primary {
        display: inline-flex;
        align-items: center;

        width: max-content;
        margin-top: $dims_padding-large * 3;

        .icon {
            stroke: $color_font-light;
            margin-left: $dims_padding;
        }
    }
}
