.ChartsPanel {
  position: fixed;
  display: inline-block;
  overflow: hidden;
  pointer-events: none;

  &:not(.cPanelclosed) {
    position: absolute;
    left: $dims_padding;
  }

  .szh-menu-button {
    top: -4px;
    border: 1px solid $color_bg-dark;
    border-radius: $box_border-radius;
    padding: 5px;

    &:hover {
        opacity: .5;
    }
  }

  .cPanel {
    position: absolute;
    top: 0;
    bottom: $panel-space;
    left: $panel-space;
    right: $panel-space;

    z-index: 1;

    &.panel--closed {
        width: $dims_button-size + $dims_padding;
        top: auto;
        left: auto;

        .panel-toggle button {
            padding: 0;
        }
    }
  }

  .panel__header {
      margin-bottom: 0;
  }

  .charts {
      display: flex;
  }

  .ChartWrapper {
      flex: 1 1 100%;
    background: rgb(255,255,255);

    background-color: var(--token-65abf455-0aa4-4ded-bbff-9b96c658c4f6, #ffffff);
    border-radius: 5px;
    border: 1px solid var(--token-379d1df1-6d46-4d8f-b74f-6b6968434d74, #f2f5fa) /* {"name":"01"} */;

    & > div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .filter-toggle {
      top: -5px;

      .ReactForm__CheckboxSlider {
          margin-left: $dims_padding;
      }
  }

  .chartFilter {
    display: inline-block;
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;

    .filter {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      height: 25px;
      margin-left: 15px;
      padding: 0 5px 0 1px;

      font-size: 1.1rem;
    }

    .indChartsLabel {
      border-radius: 5px;
      border: 1px solid #7d7a96; /* {"name":"09"} */

      label {
        cursor: pointer;
        flex-shrink: 0;
        overflow: visible;
        white-space: pre;
        font-weight: 500;
        font-style: normal;
        font-family: "Inter", sans-serif;
        color: #050038;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 1;
        text-transform: uppercase;
        text-align: left;


        input {
          cursor: pointer;
          height: 17px;
          width: 17px;

        }

        .chartTitle {
          display: inline-block;
          line-height: 25px;
          vertical-align: top;
          margin-left: 3px;
        }
      }

      &.disabled {
        border-color: #ccc6f9;

        label {
          color: #ccc6f9;
        }
      }
    }
  }
}

.chartJsResponsiveParent {
  position: relative;
  width: 100%;
  height: 100%;
}
