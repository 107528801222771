.ToolBar {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: ($dims_padding-large * 3) + $dims_button-size;
    left: $dims_padding-large;
    z-index: 3;

    max-width: 40px;
    padding: 0;

    button {
        &:first-of-type {
            border-radius: $box_border-radius--small $box_border-radius--small 0 0;
        }

        &:last-of-type {
            border-radius: 0 0 $box_border-radius--small $box_border-radius--small;
        }

        &:hover {
            background: $color_bg-active;
            transition: background .2s;
        }
    }

    ul {
        border-radius: $box_border-radius--small;
        box-shadow: $box_shadow;
        transform: translateX(#{$dims_padding-large});
    }

    li {
        padding: 0.375rem 1rem;

        color: $color_font-dark;
        font-size: $font_size-small;
        font-weight: 500;

        img, span {
            display: inline-block;

            width: $font_size-medium;
            height: $font_size-medium;
            margin-right: $dims_padding;
        }

        span {
            border: 1px solid $color_bg-dark;
            border-radius: 3px;
        }
    }

    .no-hover {
        li {
            cursor: initial;
            background: initial !important;
        }
    }
}

