.InfoModal {
    .ReactModal__window {
        width: 600px;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        display: flex;

        + li {
            margin-top: $dims_padding-large;
        }
    }

    label {
        flex: 0 0 120px;
        margin: auto;

        font-size: $font_size-medium;
    }

    span {
        flex: 1;
        font-weight: 400;
        font-size: $font_size-medium;
        text-align: left;
        border-radius: $box_border-radius--small;
        background: $color_lightest-blue;
        padding: $dims_padding;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .modal-buttons {
        align-items: right;
    }

    button.primary {
        margin: 0;
        margin-left: auto;
    }

    button.text {
        margin: auto;
        margin-left: 0 !important;
        font-size: $font_size-small !important;
    }

}
