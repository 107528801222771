@import '../core';

.PanelSelect {
  select {
    font-size: 17px;
    background: $color_bg-medium;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    padding: 6px 8px;
    margin-top: -2px;
  }
}
