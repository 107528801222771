@import '../core';

.ProjectList {
  .ProjectCard {
    position: relative;
    margin: 0.25em;
    display: inline-block;
    width: 250px;
    //height: 100px;
    border-radius: 1em;
    padding: 0;
    border: 2px solid #d2d2d2;
    vertical-align: middle;

    .inner {
      padding: 0.25em;
    }

    .sel-button {
      float: right;

      button {
        padding: 0.75em 1.25em;
        margin-right: 0.5em;
        margin-bottom: 0.5em;

      }

    }

    .clickable-title {
      color: white;
      padding: 0.5em;
      border-top-right-radius: 0.85em;
      border-top-left-radius: 0.85em;
      display: flex;

      h3 {
        font-weight: normal;

        padding-left: 0.25em;
        flex: 1;
        margin: 0;
        cursor: pointer;
      }

      .Checkbox {

        width: 16px;
        height: 16px;
        border-radius: 50%;

        &.checked {
          background-color: $color_active;

          &:after {
            content: url(../assets/checkmark.svg);
            width: 17px;
            left: 0;
            top: 0;
            display: block;
            position: absolute;
            transition: top .15s;
            pointer-events: none;
          }
        }
      }
    }

    .data-element {
      margin-top: 0.5em;
      margin-left: 0.5em;
    }


    &.selected {
      border: 2px solid $color_active;
    }

    &.disabled {
      .clickable-title {
        color: #878787;

      }
    }

    .row {
      display: flex;

      margin-bottom: $dims_padding;

      .name {
        width: 8rem;
        font-size: 0.95em;
        padding: $dims_padding;;

        text-align: right;
        text-transform: uppercase;
      }

      .value {
        //width: 100%;
        padding: $dims_padding;

        background-color: $color_lightest-blue;
        border-radius: $box_border-radius--medium;
      }
    }
  }


}
