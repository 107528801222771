.MenuBar {
    @include no-bg;

    display: flex;
    width: max-content;
    z-index: 1;

    & > * {
        vertical-align: top;
        display: inline-block;
    }

    .ribbon {
        overflow: hidden;
        pointer-events: none;
        display: flex;
    }

    .file-actions {
        left: 0;
        transition: left .5s;
    }

    &.expandable {
        .file-actions {
            left: -31rem;
        }
    }

    &.expanded {
        .file-actions {
            left: 0;
            transition: left .5s;
        }

        .expand-toggle .icon {
            transform: rotate(180deg);
        }
    }

    .title {
        display: flex;
        align-items: center;
        z-index: 2;

        width: max-content;
        height: $dims_button-size + $dims_padding;

        padding: $dims_padding;
        background: $color_bg-dark;
        border-radius: $box_border-radius--small;
        box-shadow: $box_shadow;

        img {
            height: $dims_button-size * .875;
        }

        h4 {
            width: 13.636rem;
            margin: auto;

            color: $color_font-light;
            font-weight: 500;
            font-size: $font_size-regular;
            text-align: center;
        }
    }

    .scenario-name-wrapper {
        border-right: 2px solid $color_bg-medium;

        display: flex;
        align-items: center;
        text-align: center;
        background: $color_bg-light;

        .scenario-name {
            display: flex;
            width: 90%;
            border-radius: 20px;
            overflow: hidden;
            margin: auto;
            margin: auto;
        }

        label {
            display: flex;
            vertical-align: middle;

            height: auto;
            padding: $dims_padding;

            color: $color_font-medium;
            font-size: $font_size-xsmall;
            font-weight: 400;
            text-transform: uppercase;

            background: $color_bg-active;
        }

        input {
            display: inline-block;

            padding: 0 $dims_padding;

            font-size: $font_size-regular;
            text-overflow: ellipsis;

            background: $color_bg-medium;
            border: none;
            overflow: hidden;
            outline: none !important;
        }
    }

  .file-actions {
    display: flex;
    z-index: 1;

    height: $dims_button-size;
    padding: 0;
    margin: auto 0;
    margin-right: 2rem;

    background: none;
    border-radius: 0 $box_border-radius $box_border-radius 0;
    box-shadow: $box_shadow;
    overflow: hidden;

    .icon {
        top: -.1rem;
    }

    button {
        &:hover {
            background: $color_bg-med-light;
            transition: background .2s;
        }

        &.show-save-toggle:hover {
            cursor: initial;
        }

        &.expand-toggle {
            .icon {
                top: -2px;
                width: $font_size-medium;
                margin: 0;
                padding: 0;

                + .icon {
                    margin-left: -.5em;
                }
            }
        }
    }
  }

  .SaveOptions {
      $_top: $dims_button-size * 1.5;

      position: absolute;
      left: 94%;
      top: $_top * 1.3;

      min-width: 250px;
      padding: $dims_padding-large;

      background: $color_bg-light;
      border-radius: $box_border-radius;
      box-shadow: $box_shadow;
      opacity: 0;
      pointer-events: none;
      transition: top .4s, opacity .3s;

      * {
          pointer-events: none;
      }

      &:before {
          content: '';
          position: absolute;
          top: -30%;
          display: block;

          height: 100%;
          width: 100%;

          pointer-events: none;
      }

      &.active, &:hover {
          top: $_top;

          opacity: 1;
          pointer-events: all;
          transition: top .4s, opacity .3s;

          &:before {
              pointer-events: all;
          }

          * {
              pointer-events: inherit;
          }
      }

      button {
          width: 100%;

          border-radius: $box_border-radius;
          box-shadow: $box_shadow;

          &:nth-of-type(2) {
              margin-top: $dims_padding;
          }
      }
  }

}

